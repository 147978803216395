<div class="onboarding-container">
    <div class="logo-container">
      <div class="logo-one"></div>
      <div class="logo-two"></div>
    </div>
    <div class="forms-container">
      <div class="onboarding-image-one"></div>
      <div class="onboarding-image-two"></div>


      <div class="form-header">
        <div class="title">Reset password</div>
        <div class="description">or <a id="anchor" (click)="navigateLogin()">log in</a></div>
      </div>
      <div class="row form-fitday form-one">
        <div class="col-12" >
          <div class="form-field">
            <label>Password</label>
            <input type="password" name="password"  maxlength="50" [(ngModel)]="data.password" [ngClass]="{'invalid': notMatch}">
            <div *ngIf="notMatch" class="form-error-msg">Please match the passwords</div>

          </div>
          <div class="form-field">
            <label>Confirm password</label>
            <input type="password" name="password"  maxlength="50" [(ngModel)]="data.password_confirmation" [ngClass]="{'invalid': notMatch}" >
            <div *ngIf="notMatch" class="form-error-msg">Please match the passwords</div>

          </div>
          <button class="btn-default" (click)="resetPassword()">
            RESET PASSWORD
          </button>
        </div>


      </div>


    </div>
  </div>
