<div class="banner-wrap">
    <div class="d-flex justify-content-center" style="margin: 16px 8px;" *ngIf="firstPage">
        <div class="demo-banner-wrap">
            <div class="lef-banner">
                <img src="../../../../assets/img/caller.svg" alt="caller">
                <span>Don’t have an account yet? <br>
                    Let’s get you started!</span>
            </div>
            <button class="btn-default" style="text-transform: none;" (click)="bookeDemo()">Book a demo</button>
        </div>
    </div>


    <div class="cookie-wrap" *ngIf="checkCookieStatus === null || checkCookieStatus === undefined" >
        <div class="cancel-wrap" (click)="onSetCookieStatus(false)">
            <img src="../../../../assets/img/cross-gray.svg" alt="">
        </div>

        <div class="banner-container">
            <div class="privacy-text">
                We use cookies to enhance your product experience. <br>
                By continuing to use our product, we’ll assume you are happy with it.
            </div>
    
            <div class="button-wrap">
                <button type="submit"  class="btn-default" style="text-transform: none;" (click)="onSetCookieStatus(true)">Yes, I’m happy!</button>
            </div>
        </div>
    </div>

</div>
