<section class="contact-section" *ngIf="!form_submitted">
	<div class="ajax-screen" #ajax_screen></div>
	<form class="contact-form" #add_customer="ngForm" (ngSubmit)="addNewCustomer(add_customer,$event)" novalidate>

		<h3><span *ngIf="center_name">{{center_name}}</span></h3>

		<div class="field five validate-field">
			<input type="text" disabled name="contact-phone" [(ngModel)]="customer_data.contact.phone" />
			<label> Phone number </label>
		</div>
		<div class="field five validate-field margin-right16 display-inline-block">
			<input type="text" minlength="3" name="contact-first-name" [(ngModel)]="customer_data.contact.first_name"
				[disabled]="found_contact" (keyup)="isFilled($event)" />
			<label> First name </label>
		</div>
		<div class="field four validate-field display-inline-block">
			<input type="text" minlength="3" name="contact-last-name" [(ngModel)]="customer_data.contact.last_name"
				[disabled]="found_contact" (keyup)="isFilled($event)" />
			<label> Last name </label>
		</div>

		<div class="ten position-relative">
			<span class="header-label"> Gender </span>
			<div class="gender-container">
				<input type="radio" name="contact-gender" id="gender-male" value="male" class="four"
					[disabled]="found_contact" [(ngModel)]="customer_data.contact.gender" />
				<label for="gender-male" class="margin-right16">
					<img src="../../../../assets/img/male-colored.svg" alt="male" />
					<span>Male</span>
				</label>
				<input type="radio" name="contact-gender" value="female" id="gender-female" class="four"
					[disabled]="found_contact" [(ngModel)]="customer_data.contact.gender" />
				<label for="gender-female">
					<img src="../../../../assets/img/female-colored.svg" alt="male" />
					<span>Female</span>
				</label>
			</div>
		</div>

		<div class="field form-link-datepicker six">
			<md2-datepicker required type="date" format="dd MMM yy" name="contact-dob" container="dialog"
				touchUi="dialog" startView="year" openOnFocus="{{true}}"
				[(ngModel)]="customer_data.contact.date_of_birth">
			</md2-datepicker>
			<label> Date of birth </label>
		</div>

		<div class="field ten validate-field">
			<input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="contact-email"
				[(ngModel)]="customer_data.contact.email" (keyup)="isFilled($event)" />
			<label> Email </label>
		</div>

		<div class="field three validate-field">
			<input type="text" pattern="[0-9]{6}" minlength="6" maxlength="6" name="address-pincode"
				[(ngModel)]="customer_data.address.pincode" (keyup)="getLocation($event)" />
			<label> Pincode </label>
		</div>

		<div class="field ten validate-field">
			<textarea required name="address-address" [(ngModel)]="customer_data.address.address"
				(keyup)='autoIncreaseSize($event)'></textarea>
			<label> Flat/house, street, locality </label>
		</div>

		<div class="field seven validate-field">
			<input type="text" required name="address-city" [(ngModel)]="customer_data.address.city"
				(keyup)="isFilled($event)" />
			<label> City </label>
		</div>

		<div class="field seven validate-field">
			<input type="text" required name="address-state" [(ngModel)]="customer_data.address.state"
				(keyup)="isFilled($event)" />
			<label> State </label>
		</div>

		<h4 *ngIf="photo_enabled">Profile picture </h4>

		<div class="position-relative ten validate-field" *ngIf="photo_enabled">
			<label class="display-block align-center padding-bottom20 small-text dark-color-text-2">Upload a close up
				picture of your face</label>

		</div>

		<h4 *ngIf="emergency_contact_enabled"> Emergency contact </h4>

		<div class="field five validate-field margin-right16 display-inline-block" *ngIf="emergency_contact_enabled">
			<input type="text" minlength="3" name="emergency-contact-first-name"
				[(ngModel)]="customer_data.emergencyContact.first_name" (keyup)="isFilled($event)" />
			<label> First name </label>
		</div>
		<div class="field four validate-field display-inline-block" *ngIf="emergency_contact_enabled">
			<input type="text" minlength="3" name="emergency-contact-last-name"
				[(ngModel)]="customer_data.emergencyContact.last_name" (keyup)="isFilled($event)" />
			<label> Last name </label>
		</div>

		<div class="field five validate-field" *ngIf="emergency_contact_enabled">
			<input type="text" maxlength="10" minlength="10" pattern="[789][0-9]{9}" name="emergency-contact-phone"
				[(ngModel)]="customer_data.emergencyContact.phone" (keyup)="isFilled($event)" />
			<label> Phone number </label>
		</div>

		<div class="field form-link-selectbox ten" *ngIf="emergency_contact_enabled">
			<mat-select class="margin-top16" name="emergency-contact-relationship"
				[(ngModel)]="customer_data.emergencyContact.relationship">
				<mat-option [value]="null">Select</mat-option>
				<mat-option value="sibling">Sibling</mat-option>
				<mat-option value="mother">Mother</mat-option>
				<mat-option value="father">Father</mat-option>
				<mat-option value="guardian">Guardian</mat-option>
				<mat-option value="other">Others</mat-option>
			</mat-select>
			<label> Relationship </label>
		</div>

		<h4 *ngIf="medical_history_enabled">Health record </h4>

		<div class="field ten validate-field" *ngIf="medical_history_enabled">
			<textarea minlength="1" name="health-record" [(ngModel)]="customer_data.medical_history">
				(keyup)='autoIncreaseSize($event)'></textarea>
			<label> medical history of diseases and allergies </label>
		</div>

		<h4 *ngIf="membership_id_enabled">Custom Fields </h4>

		<div class="field ten validate-field" *ngIf="membership_id_enabled">
			<input type="text" minlength="3" name="custom-field-membership-id"
				[(ngModel)]="customer_data.custom_fields.membership_id" (keyup)="isFilled($event)" />
			<label> Membership ID </label>
		</div>

		<button type="submit" class="" #submit_btn> Submit </button>

		<p class="form-footer"><span style="font-size: 14px; position: relative;top:2px">&copy;</span> GYMDAY TECHNOLOGY
		</p>
	</form>
</section>

<section class="success-screen" *ngIf="form_submitted">
	<h4>Details sent</h4>
	<p>We have notified <br> <span *ngIf="center_name">{{center_name}}</span><br>feel free to inform them personally
	</p>

	<div class="images">
		<img src="../../../../assets/img/plane.svg" class="plane" alt="male" />
		<img src="../../../../assets/img/confetti2.svg" class="confetti" alt="male" />
		<img src="../../../../assets/img/plane-shadow.svg" class="shadow" alt="male" />
	</div>
	<h6> You can close this tab now </h6>
</section>
