export const environment = {
    production: true,
    baseUrl: "https://data.bookeeapp.com/",
    newCrmUrl: "https://crm.app.bookeeapp.com",
    baseApiUrlAutomation: "https://aurora.bookeeapp.com/",
    baseUrlCampaign: "https://campaign.bookeeapp.com/",
    pusherKey: "661d4be379f71c3f0ef5",
    logRocket: true,
    intercomAppId: "gw2tyo2y",
    chargebee: 'bookeeapp',
    adyenMode: 'live',
    env_name: 'production',
    posthog_api_key: 'phc_EXhFiil5w6h3ETleJIVLQk2owLS7grHJi2Z0oEywazh',
    chargebee_api_key: 'live_2lxqGtIKe8KkdMEAJyNPKr5HFdcu95IL8',
    ai_base_url: 'https://anton.bookeeapp.com',
    challenge: 'https://22bfd41a62e0.us-east-1.sdk.awswaf.com/22bfd41a62e0/f9417369e628/challenge.js',
    google_secret: "AIzaSyDkJ_WnY7vm1jTHUYI8YWREXSvvjNkfcoM&libraries=places"
};
