<section>
	<div class="ajax-loader" #ajax_loader></div>
     <form class="signup" id="signupForm" name="form"
     	#signup="ngForm"
     	(ngSubmit)="signupClient(signup,$event)"
     	*ngIf="!sign_up_done">

		<h3> Sign up </h3>
		<div class="input-field validate-field" id="fullname-div">
			<input id="fullname" required type="text" placeholder="Full name" name="fullname"
				[(ngModel)]="client.username"
				(input)="isFilled($event)">
		</div>
		<div class="input-field validate-field" id="email-div">
			<input id="email" type="email" required placeholder="Email" name="email"
				[(ngModel)]="client.email"
				(input)="isFilled($event)">
		</div>
		<div class="input-field validate-field" id="password-div">
			<input id="password" type="password" required placeholder="Password" name="password"
				[(ngModel)]="client.password"
				(focus)="password_flag=true"
				(blur)="password_flag=false"
				(input)="isFilled($event)">
			<p [class.active]="password_flag==true"> Minimum 6 characters and atleast one number </p>
		</div>

		<aside class="error smaller-text" [class.visibility-none]="!error">
			<img src="../../../../assets/img/inline-error-icon.svg" alt="Error" class="margin-right8"/>
			{{error}}
		</aside>
		<button class="btn btn-secondary btn-large display-block text-uppercase light-color-text-1" type="submit">
			Sign up for 15 days trial
		</button>
	</form>

	<section class="signup-link-sent" id="signup-link-sent"
		*ngIf="sign_up_done">

		<aside class="smaller-text dark-color-text-3 cursor-pointer margin-top24"
			(click)="sign_up_done=false">
			<img src="../../../../assets/img/back-small.svg" alt="Back" class="margin-right8"/>
			Back to sign up
		</aside>

		<img src="../../../../assets/img/link-sent-success.svg" alt="Back" class="margin-right8"/>

		<p class="medium-text dark-color-text-1 align-center"> Email verification link has been sent to <br> {{client.email}} </p>

		<span class="smallest-text display-block dark-color-text-2 align-center margin-bottom8"> Didn't receive the email? Check spam or resend link from below </span>

		<button class="btn btn-gray-hollow theme-color-text-2 semibold-font btn-large display-block text-uppercase light-color-text-1" (click)="resendSignUpLink($event)"> Resend link </button>

	</section>

</section>
