<section>
	<div class="ajax-loader" #ajax_loader></div>	
	<form class="password-reset" id="password-reset-form" 
		*ngIf="!password_changed" 
		#password_reset="ngForm" 
		(ngSubmit)="setPassword(password_reset,$event)">
		<p class="medium-text dark-color-text-2 margin-bottom8 padding-top16">{{data.email}}</p> 
		<h3> Set password </h3>
		<div class="input-field validate-field">
			<input id="password" type="password" required placeholder="Password" name="password" 
				[(ngModel)]="data.password" 
				(input)="isFilled($event)">
		</div>
		<div class="input-field validate-field">
			<input id="password-confirmation" type="password" required placeholder="Password confirmation" name="password-confirmation" 
				[(ngModel)]="data.password_confirmation" 
				(input)="isFilled($event)">
		</div>
		<aside class="error smaller-text margin-top16 margin-bottom16" [class.visibility-none]="!error"> 
			<img src="../../../../assets/img/inline-error-icon.svg" alt="Error" class="margin-right8"/>
			{{error}}
		</aside>
		<button class="btn btn-secondary semibold-text btn-large display-block text-uppercase light-color-text-1" type="submit"> Set password </button>
	</form>

	<section class="password-link-sent align-center" id="password-link-sent"
		*ngIf="password_changed">

		<p class="medium-text dark-color-text-2 margin-bottom40">{{data.email}}</p>

		<img src="../../../../assets/img/link-sent-success.svg" alt="Success" class="margin-bottom32"/>

		<p class="medium-text dark-color-text-1 align-center padding-bottom40 "> Password has been set, please login to your account using the your password </p>

		<a class="btn btn-gray-hollow theme-color-text-2 margin-top20 semibold-font btn-large display-block text-uppercase light-color-text-1"
			[routerLink]="['../../../../login']"
		> GO TO LOGIN </a>

	</section>

</section>